<template>
  <b-modal
    id="modal-search-coupon"
    title="選擇優惠券"
    @show="initializeTempCoupons"
    no-close-on-backdrop
  >
    <b-input-group>
      <b-form-input placeholder="請輸入優惠券名稱" v-model="query.keyword" @keyup.enter="fetchCoupons"></b-form-input>
      <b-input-group-append>
        <b-button @click="fetchCoupons"><i class="fa fa-search"></i></b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="text-center mt-3 mb-3 loading-spinner" v-if="isLoading">
      <b-spinner variant="secondary" small />
    </div>
    <div class="mt-3 mb-3" v-else-if="coupons.length > 0">
      <div
        v-for="coupon in coupons"
        v-bind:key="coupon.redeem_code_bundle_id"
        class="d-flex align-items-center py-2 coupon-item"
        @click="handleSelected(coupon)"
      >
        <template>
          <b-img-lazy
            v-if="coupon.img_url"
            class="mx-2 thumb-image flex-shrink-0"
            :src="coupon.img_url"
            alt="優惠券圖片"
            width="50"
            height="25"
          />
          <div v-else class="mx-2 flex-shrink-0" :style="{ width: '50px', height: '25px'}"></div>
        </template>
        <div class="mr-auto text-nowrap text-truncate">
          <div class="font-weight-bold">
            {{ coupon.name }}
          </div>
          <small class="text-muted">
            {{ format(new Date(coupon.start_at), 'yyyy-MM-dd HH:mm') }} ~ {{ format(new Date(coupon.end_at), 'yyyy-MM-dd HH:mm') }}
          </small>
        </div>
        <div class="mx-2">
          <span v-if="selectedCouponIds.includes(coupon.id)" class="mdi mdi-check-circle mdi-24px" />
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <div class="float-right">
          <span
            class="mr-2 mdi mdi-chevron-left mdi-36px"
            :class="meta.current_page == 1 ? 'text-muted': ''"
            @click="prevPage">
          </span>
          <span
            class="mr-5 mdi mdi-chevron-right mdi-36px"
            :class="meta.current_page == meta.last_page ? 'text-muted': ''"
            @click="nextPage">
          </span>

          <b-button
            variant="danger"
            size="sm"
            class="mr-2 ml-5"
            @click="closeModal"
          >
            取消
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="submitSelectedCoupon"
          >
            確定
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { format } from "date-fns";
import redeemCodeBundleApi from "@/apis/redeemCodeBundle";

export default {
  props: {
    mode: {
      type: String,
      default: "single", // enum: single, multiple
    },
    selectedCoupon: {
      type: Object,
      default: null,
    },
    selectedCoupons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      coupons: [],
      localCoupons: [],
      meta: {},
      query: {
        page: 1,
        per_page: 10,
        merchant_id: null,
        create_type: "0",
        keyword: "",
      },
    };
  },
  computed: {
    selectedCouponIds() {
      return this.localCoupons.map((coupon) => coupon.id)
    }
  },

  watch: {
    "query.keyword"() {
      this.query.page = 1;
    },
  },

  created() {
    this.fetchCoupons();
  },

  methods: {
    format,
    async fetchCoupons() {
      try {
        this.isLoading = true;
        const params = {
          ...this.query,
        };
        const { data } = await redeemCodeBundleApi.getRedeemCodeBundles(params);
        this.meta = data.meta;
        this.coupons = data.data;
      } catch (error) {
        console.log("Error fetching coupons:", error);
      }
      this.isLoading = false;
    },

    // 初始化時設置選擇的優惠券
    initializeTempCoupons() {
      if (this.mode === 'single' && this.selectedCoupon) {
        this.localCoupons = [this.selectedCoupon];
      } else {
        this.localCoupons = this.selectedCoupons;
      }
    },

    handleSelected(coupon) {
      const index = this.localCoupons.findIndex(({ id }) => id === coupon.id);

      if (index === -1) {
        if (this.mode === 'single') {
          this.localCoupons = [coupon];
        } else {
          this.localCoupons.push(coupon);
        }
      } else {
        this.localCoupons.splice(index, 1);
      }
    },

    submitSelectedCoupon() {
      this.$bvModal.hide('modal-search-coupon');

      if (this.localCoupons.length)  {
        this.$emit(
          'submit',
          this.mode === 'single' ? this.localCoupons[0] : this.localCoupons
        );
      }
    },

    closeModal() {
      this.$bvModal.hide('modal-search-coupon');
    },

    prevPage() {
      if (this.meta.current_page == 1) {
        return;
      }
      this.query.page -= 1;
      this.fetchCoupons();
    },

    nextPage() {
      if (this.meta.current_page == this.meta.last_page) {
        return;
      }
      this.query.page += 1;
      this.fetchCoupons();
    }
  }
}
</script>

<style lang="scss">
.coupon-item:hover {
  cursor: pointer;
  background-color: #c7c7c7;
  color: white;
  width: 100%;

  .staff-list-info {
    color: white;
  }
}
</style>
